import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// import styled from "@emotion/styled";
import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import styles from '../constants/theme'

import SEO from '../components/seo'
import Link from '../components/Link'
import Layout from '../components/layout'
import Text from '../components/Text'
import H1 from '../components/H1'
import HeroBG from '../components/HeroBG'
import MainContentWrap from '../components/MainContentWrap'
import ContentSection from '../components/ContentSection'
import CustomContainer from '../components/CustomContainer'

export default function Affiliations() {
	const pageQuery = useStaticQuery(graphql`
		query affiliatePageQuery {
			affiliationsPage: wordpressPage(slug: { eq: "affiliations" }) {
				title
				acf {
					hero_image {
						localFile {
							childImageSharp {
								fluid(quality: 90, maxWidth: 1920, toFormat: JPG) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					affiliators_list_header
					affiliators {
						name
						link
						image {
							localFile {
								childImageSharp {
									fixed(height: 80, quality: 80, toFormat: JPG) {
										src
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	const acf = pageQuery.affiliationsPage.acf

	return (
		<Layout>
			<SEO title={pageQuery.affiliationsPage.title} keywords={[ '' ]} />
			<HeroBG imageURL={acf.hero_image.localFile.childImageSharp.fluid} />
			<div className="container text-center">
				<ThemeProvider theme={styles.palette.blue}>
					<H1>{pageQuery.affiliationsPage.title}</H1>
				</ThemeProvider>
			</div>
			<MainContentWrap>
				<CustomContainer>
					<ContentSection>
						<Text h2 className="text-center" css={css`margin-bottom: ${styles.margin.mid};`}>
							{acf.affiliators_list_header}
						</Text>
						<div className="row">
							{acf.affiliators.map((affiliator, i) => (
								<div
									className="col-md-6"
									key={`affiliator_${i}`}
									css={css`
										display: flex;
										justify-content: space-between;
										align-items: center;
										flex-direction: column;
										border-bottom: 2px solid ${styles.colors.whiteAlt};
										margin-bottom: ${styles.margin.mid};
									`}
								>
									<Link
										to={affiliator.link}
										css={css`
											margin-bottom: ${styles.margin.small};
											text-align: center;
										`}
									>
										<div
											css={css`
												height: 100px;
												${styles.flexCenter()};
											`}
										>
											<img
												src={affiliator.image.localFile.childImageSharp.fixed.src}
												alt=""
												css={css`max-width: 80%;`}
											/>
										</div>
										<Text css={css`color: ${styles.colors.blueDark};`}>{affiliator.name}</Text>
									</Link>
								</div>
							))}
						</div>
					</ContentSection>
				</CustomContainer>
			</MainContentWrap>
		</Layout>
	)
}
